<template>
    <div class="animated fadeIn">
        <!-- Detail -->
        <b-card-header>
          Detail Information
          <div class="card-header-actions">
            <a class="card-header-action" href="partner-voucher" rel="noreferrer noopener">
              <small class="text-muted">Back</small>
            </a>
          </div>
        </b-card-header>
        <b-card>
            <b-row>
                <b-col class="mb-5" sm="4">
                    <p class="mb-1">Created Date</p>
                    <p><strong>{{ this.data.created_date || '-' }}</strong></p>
                </b-col>
                <b-col class="mb-5" sm="4">
                    <p class="mb-1">Partner Name</p>
                    <p><strong>{{ this.data.name || '-' }}</strong></p>
                </b-col>
                <b-col class="mb-5" sm="4">
                    <p class="mb-1">Voucher Prefix</p>
                    <p><strong>{{ this.data.prefix || '-' }}</strong></p>
                </b-col>
                <b-col class="mb-5" sm="4">
                    <p class="mb-1">Voucher Start Time</p>
                    <p><strong>{{ this.data.start_time || '-' }}</strong></p>
                </b-col>
                <b-col class="mb-5" sm="4">
                    <p class="mb-1">Voucher Expired Time</p>
                    <p><strong>{{ this.data.expired_time || '-' }}</strong></p>
                </b-col>
                <b-col class="mb-5" sm="4">
                    <p class="mb-1">Voucher Name</p>
                    <p><strong>{{ this.data.name || '-' }}</strong></p>
                </b-col>
                <b-col class="mb-5" sm="4">
                    <p class="mb-1">Voucher Code</p>
                    <p><strong>{{ this.data.code || '-' }}</strong></p>
                </b-col>
                <b-col class="mb-5" sm="4">
                    <p class="mb-1">Partner Invoice Number</p>
                    <p><strong>{{ this.data.partner_invoice_number || '-' }}</strong></p>
                </b-col>
            </b-row>
        </b-card>
        <b-card no-body>
            <b-card-header>
                Delivery History
            </b-card-header>
            <b-card-body>
                <div class="loading" v-if="isLoading == true">
                    <div class="sk-three-bounce">
                        <div class="sk-child sk-bounce1"></div>
                        <div class="sk-child sk-bounce2"></div>
                        <div class="sk-child sk-bounce3"></div>
                    </div>
                </div>
                <div>
                    <vuetable
                        ref="vuetable"
                        :api-mode="false"
                        :data="data.delivery_history"
                        :fields="fields"
                    >
                        <template slot="attempt-slot" slot-scope="prop">
                            <span>{{ prop.rowData.attempt_number || '-' }}</span>
                        </template>
                        <template slot="phone-number-slot" slot-scope="prop">
                            <span>{{ prop.rowData.phone_number || '-' }}</span>
                        </template>
                        <template slot="delivery-status-slot" slot-scope="prop">
                            <span :class="prop.rowData.delivery_status == 'Failed' ? 'text-danger' : 'text-success'" >{{ prop.rowData.delivery_status || '-' }}</span>
                        </template>
                        <template slot="failed-reason-slot" slot-scope="prop">
                            <span style="color:red">{{ prop.rowData.failed_reason || '-' }}</span>
                        </template>
                    </vuetable>
                </div>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
  import axios from 'axios'
  import Vue from 'vue'
  import Vuetable from 'vuetable-2/src/components/Vuetable'

  Vue.use(Vuetable)

  export default {
    components:{
        Vuetable
    },
    data() {
        return {
            data: {},
            isLoading:false,
            fields: [
                {
                    name: 'attempt-slot',
                    sortField: 'attempt_number',
                    title: 'Attempt Number'
                },
                {
                    name: 'phone-number-slot',
                    sortField: 'phone_number',
                    title: 'Phone Number'
                },
                {
                    name: 'delivery-status-slot',
                    sortField: 'delivery_status',
                    title: 'Delivery Status'
                },
                {
                    name: 'failed-reason-slot',
                    sortField: 'failed_reason',
                    title: 'Failed Reason'
                },
            ]
        }
    },
    created() {
        this.isLoading = true
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
        this.$http.get(`/voucher/partner/junk/details?hashed_id=${atob(this.$route.params.id)}`)
            .then((result) => {
                this.data = result.data.data;
                this.isLoading = false
            }).catch((error) => {
                if (error.response) {
                    this.isLoading = false
                    this.errors.code = error.response.status;
                    this.errors.message = error.response.data.meta.message;
                    this.errors.status = error.response.data.meta.code;
                }
            })
    },
    methods:{
        handleLoadError(error) {
            this.errors.code = error.response.data.meta.code;
            this.errors.message = error.response.data.meta.message;
            this.errors.status = error.response.data.meta.code;
            if (this.errors.code == 401) {
                if (localStorage.getItem('access_token') != null) {
                localStorage.removeItem('access_token');
                this.$swal.fire(
                    'Your session expired!',
                    'Your session has expired. Please login again to access this page!',
                    'error'
                ).then(() => {
                    this.$router.push('/login')
                })
                }
            }else if(this.errors.code == 403) {
                this.$router.push('/403')
            }else if (this.errors.code == 500) {
                this.$router.push('/500')
            }
        },   
    },
  }
</script>
